var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xw-full xpb-[60px]",attrs:{"flat":"","min-height":"80vh"}},[_c('AdminOwners'),_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xgap-2 xitems-center"},[_c('app-search',{staticClass:"md:xmax-w-[300px] xmax-w-full",attrs:{"api":("api/workspaces/" + (_vm.user.team_id) + "/users-search"),"item-text":"real_name","item-value":"id"},on:{"selected":_vm.onSelect,"cleared":_vm.fetchUsers}}),_c('div',{staticClass:"xflex xjustify-end xitems-center xgap-[0.5em]",class:[
        _vm.tabletDown ? 'xw-full' : '',
        _vm.mobile ? 'xflex-col' : 'xflex-row' ]},[(_vm.user)?_c('SyncToSlack',{attrs:{"block":_vm.mobile,"team-id":_vm.user.team_id}}):_vm._e(),(_vm.$can.and('update_subscription'))?_c('btn-tooltip',{attrs:{"block":_vm.mobile,"color":"primary","tip":"Save changes"},on:{"click":_vm.saveChanges}},[_vm._v(" Save Changes ")]):_vm._e()],1)],1),_c('v-card-text',[_c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items,"empty-text":"No users found."},scopedSlots:_vm._u([{key:"username",fn:function(ref){
      var item = ref.item;
return [(item)?_c('router-link',{attrs:{"to":{
            name: 'app-user-record-monthly',
            params: {
              id: item.id,
              year_month: _vm.yearMonth,
            },
          }}},[_c('avatar',{attrs:{"size":"35","user":item,"with-name":""}})],1):_vm._e()]}},{key:"created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"LL")))])]}},{key:"type",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.role ? item.role.normalize_name : "Slack User"))])]}},{key:"is_paying",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":!_vm.$can.and('manage_member_subscription'),"inset":"","color":"#19CB40","true-value":true,"false-value":false},model:{value:(item.is_paying),callback:function ($$v) {_vm.$set(item, "is_paying", $$v)},expression:"item.is_paying"}})]}},{key:"data_center",fn:function(ref){
          var item = ref.item;
return [_c('DataCentersSelect',{staticClass:"xmax-w-[350px]",attrs:{"disabled":!_vm.$can.and('manage_member_subscription')},model:{value:(item.data_centers_id),callback:function ($$v) {_vm.$set(item, "data_centers_id", $$v)},expression:"item.data_centers_id"}})]}}])})],1),(_vm.next_page_url)?_c('v-card-actions',{staticClass:"xflex-col xflex xjustify-center xitems-center xw-full xh-[60px] xabsolute xbottom-0"},[_c('btn-tooltip',{staticClass:"!xpx-[2em]",attrs:{"loading":_vm.loading_more,"color":"primary","tip":"Load more..."},on:{"click":_vm.fetchMoreUser}},[_vm._v(" Load more ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }