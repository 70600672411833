<template>
  <v-card flat>
    <v-card-title
      class="xfont-[600] xtext-[20px] xleading-[24px] xtext-[#2e2e2e]"
    >
      Admin & Owners
    </v-card-title>
    <v-slide-group
      class="xflex xflex-row xjustify-start xitems-stretch xmax-w-full xoverflow-y-auto"
    >
      <v-slide-item v-for="user in superiors" :key="user.id">
        <div
          class="xflex xflex-col xjustify-center xitems-center xgap-[5px] xm-[1em]"
        >
          <v-avatar size="106">
            <v-img contain :src="user.image_url"></v-img>
          </v-avatar>
          <h3 class="xfont-[400] xtext-[#2e2e2e] xtext-[16px] xleading-[20px]">
            {{ user.real_name | ucwords }}
          </h3>
          <span
            class="xfont-[500] xtext-[#9a9a9a] xtext-[12px] xleading-[14px]"
          >
            {{
              user.is_primary_owner
                ? "Primary Owner"
                : user.is_owner
                ? "Owner"
                : "Admin"
            }}
          </span>
          <span
            class="xfont-[500] xtext-[#9a9a9a] xtext-[12px] xleading-[14px]"
          >
            {{ user.email }}
          </span>
          <div
            class="xflex xflex-row xjustify-between xw-full xitems-center"
            v-if="hasEnablePay"
          >
            <span
              class="xfont-[400] xtext-[#9a9a9a] xtext-[12px] xleading-[14px]"
            >
              Enable User
            </span>
            <v-switch
              color="#19CB40"
              dense
              inset
              @change="$emit('enable-pay', $event)"
            ></v-switch>
          </div>
          <div
            class="xflex xflex-row xjustify-center xmt-[5px] xw-full xitems-center"
            v-if="hasEditRoles"
          >
            <btn-tooltip
              v-if="$can.or('assign_roles|revoke_roles')"
              @click="$emit('edit-roles', user)"
              tip="Update user roles & permissions"
              small
              color="primary"
            >
              <v-icon left>mdi-account-cog</v-icon>
              Manage Permissions
            </btn-tooltip>
          </div>
        </div>
      </v-slide-item>
    </v-slide-group>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  props: {
    hasEnablePay: { type: Boolean, default: false },
    hasEditRoles: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters("workspace", ["superiors"]),
  },
};
</script>

<style lang="scss" scoped></style>
